<template>
  <div>
    <div class="payment-types-wrapper">
      <div v-if="useragent === 'browser' || useragent === 'tezkor'">
        <div class="payment-types-title">{{ $t('payment-methods') }}</div>
        <CardSlider
          :cards="cards"
          @selectedCard="selectedCard"
          @payCard="payCard"
          @addCard="addCard"
          :loading="loadingCard"
          :color="color"
        ></CardSlider>
        <div class="payment-types-systems">
          <div v-for="system in paymentSystems" :key="system.name">
            <payment-system
              :paymentSystem="system"
              :loading-card="loadingCard"
              :getColor="color"
              @pay="paySystem(system.name)"
            />
          </div>
        </div>
      </div>
      <div v-if="useragent === 'multicard'">
        <transition appear name="slidePaymentCardBtn">
          <div class="text-center mb-20">
            <van-button
              :loading="loadingCard === 'multicard'"
              @click="paySystem('multicard')"
              style="width: 150px"
              :color="color"
              type="primary"
            >{{ $t("pay") }}</van-button>
          </div>
        </transition>
      </div>
      <van-popup
        position="bottom"
        v-model="addCardModal"
        class="web"
        :style="{height: 'auto', zIndex: '2005'}"
        closeable
      >
        <AddCard
          :mode="mode"
          :total="paymentEnabled ? price : 0"
          :delivery="delivery"
          :color="color"
          :tips="tips"
          :service-commission-price="serviceCommissionPrice"
          @otp="sendOtp"
          @closeModal="closeAddCard"
          @transfer="transfer"
        />
      </van-popup>
      <van-popup
        position="bottom"
        v-model="otpModal"
        class="web"
        :style="{height: 'auto', zIndex: '2005'}"
        closable
      >
        <PayCard
          :mode="mode"
          :paydata="paydata"
          :card-id="currentCardId ?? cardId"
          :total="paymentEnabled ? price : 0"
          :delivery="delivery"
          :color="color"
          :tips="tips"
          :service-commission-price="serviceCommissionPrice"
          @closeModal="closeOtp"
          @transfer="transfer"
        />
      </van-popup>
      <van-popup
        position="bottom"
        v-model="confirmModal"
        class="web"
        :style="{height: 'auto', zIndex: '2005'}"
        closable
        @closed="$emit('closeConfirm')"
      >
        <ConfirmCard
          :total="paymentEnabled ? price : 0"
          :loading="loadingCard"
          :delivery="delivery"
          :color="color"
          :tips="tips"
          :service-commission-price="serviceCommissionPrice"
          @confirm="confirmPayment"
        />
      </van-popup>
    </div>
    <div class="text-center pb-20" v-if="useragent === 'oson'">
      <van-button
        :loading="loadingCard"
        :disabled="loadingCard"
        @click="paySystem('oson')"
        style="width: 200px"
        :color="color"
        type="primary"
      >{{ $t("pay") }}</van-button>
    </div>
  </div>
</template>
<script>
import {Button, Popup} from "vant";
import CardSlider from "@/components/CardSlider";
import AddCard from "@/components/AddCard.vue";
import PayCard from "@/components/PayCard.vue";
import ConfirmCard from "@/components/ConfirmCard";
import {useUserAgent} from "@/helpers/useUserAgent";
import PaymentSystem from "@/components/PaymentSystem";

export default {
  name: "PaymentTypesBlock",
  components: {
    "van-button": Button,
    "van-popup": Popup,
    PaymentSystem,
    ConfirmCard,
    CardSlider,
    PayCard,
    AddCard,
  },
  props: {
    mode: {
      type: String,
      default: "fastpay"
    },
    price: {
      type: Number,
      default: 0
    },
    delivery: {
      type: Number,
      default: 0
    },
    tips: {
      type: Number,
      default: 0,
    },
    paymentEnabled: {
      type: Boolean,
      default: true
    },
    serviceCommissionPrice: {
      type: Number,
      default: 0
    },
    loading: {
      type: [ String, Number, Boolean ],
      default: false,
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    otp: {
      type: Boolean,
      default: false,
    },
    cardId: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#1fb7a4'
    },
    paymentSystems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      card: this.$store.state.cards[0],
      currentCardId: null,
      paydata: null,
      loadingCard: false,
      addCardModal: false,
      confirmModal: false,
      otpModal: false,
    }
  },
  mounted() {
  },
  methods: {
    selectedCard(card) {
      this.card = this.cards[card];
      this.$emit('selectCard', this.cards[card])
    },
    payCard(index) {
      const card = index ? this.cards[index] : this.card
      this.$emit('payCard', card, (result) => {
        // this.$refs.otpInput.focus();
        if (result) {
          this.paydata = result?.data?.data
          // this.currentCardId = index;
          this.otpModal = true;
        }
        this.loadingCard = false;
      })
    },
    addCard() {
      this.addCardModal = true;
    },
    closeAddCard() {
      this.addCardModal = false;
    },
    closeOtp() {
      this.otpModal = false;
    },
    confirmPayment() {
      this.loadingCard = true
      this.$emit("confirm", (otp) => {
        if (!otp) {
          this.confirmModal = false
        }
        this.loadingCard = false
      })
    },
    transfer(data) {
      this.$emit("transfer", data)
    },
    paySystem(system) {
      this.$emit("paySystem", system);
    },
    sendOtp(card, callback) {
      this.$emit('sendOtp', card, callback)
    },
  },
  watch: {
    otp(value) {
      if (value) {
        this.otpModal = true
      }
    },
    loading(value) {
      this.loadingCard = value
    },
    confirm(value) {
      this.confirmModal = !!value;
    },
    addCardModal(value) {
      this.$emit('hideNav', value)
    },
    otpModal(value) {
      this.$emit('hideNav', value)
    },
    confirmModal(value) {
      this.$emit('hideNav', value)
    },
  },
  computed: {
    cards() {
      return this.$store.state.cards;
    },
    useragent() {
      return useUserAgent().getUserAgent()
    },
  }
}
</script>
