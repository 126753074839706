<template>
  <section class="restaurant">
    <div v-if="!searching" ref="rSwiperRow" class="d-flex align-items-baseline justify-content-between marketplace-heading">
      <h1 class="marketplace-title">{{ $t("marketplace.restaurant") }} {{searching}}</h1>
      <div v-if="checkFilterStatus" @click="$emit('reset-filter')">{{$t('filters.throw')}}</div>
    </div>
    <div v-if="!searching && stores.length" class="restaurant-fixed">
      <div class="restaurant__row">
        <div @click="$emit('show-filter')" class="restaurant__filter">
          <span v-if="$store.state.paramsCount" class="stock">{{ $store.state.paramsCount }}</span>
          <p>{{ $t("marketplace.filter") }}</p>
        </div>
        <swiper
            ref="rSwiper"
            class="restaurant-swiper"
            :breakpoints="swiperRestaurantOptions.breakpoints"
            :space-between="0"
            :loop="false"
            :mousewheel="true"
        >
          <swiper-slide
              class="restaurant-swiper__slide"
              :class="{active: kitchens.length && kitchens.includes('cashback')}"
              :style="`background-image: url('/img/cashback.png')`"
              style="background-size: 98%; background-position-y: 10px"
              virtualIndex="001"
          >
            <input
                v-model="kitchens"
                value="cashback"
                checked="cashback"
                type="checkbox"
                @change="changeFilter"
            />
            <div class="restaurant-swiper__title">Кешбэк</div>
          </swiper-slide>
          <swiper-slide
              v-for="(item, index) in stores"
              :key="index"
              class="restaurant-swiper__slide"
              :class="{active: kitchens.length && kitchens.includes(item.value)}"
              :style="`background-image: url('${item.image}')`"
              :virtualIndex="++item.id"
          >
            <input
                v-model="kitchens"
                :key="index"
                :id="item.value"
                :value="item.value"
                :checked="item.value"
                type="checkbox"
                @change="changeFilter"
            />
            <div class="restaurant-swiper__title">{{ item.label.slice(0, 9) }}{{ item.label.length > 9 ? '..' : '' }}
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="restaurant__cards mt-15">
      <div v-if="activeStores.length" class="restaurant-group-wrapper" id="restaurant-scroll">
        <transition-group class="restaurant-group" name="restaurant-group">
          <template v-for="(store, index) in activeStores">
            <div
                :key="index"
                @click.prevent="goToDetail(store)"
                class="restaurant__card"
            >
              <div class="restaurant__card-top">
                <div v-if="store.cashback" class="restaurant__cashback">{{store.cashback}}%</div>
                <div v-if="store.background_url">
                  <img
                      class="restaurant__card--image"
                      :src="`${store.background_url}`"
                      alt=""
                  />
                </div>
                <div v-else class="restaurant__card--image--placeholder"></div>
                <div v-if="store" @click.stop="favorite(store)"
                     class="restaurant__card--favorite search-btn circle-btn d-flex align-items-center justify-content-center">
                  <svg v-if="!inFavorite(store)" style="margin-top: 3px" xmlns="http://www.w3.org/2000/svg" height="48"
                       viewBox="0 -960 960 960" width="48">
                    <path
                        d="M479.769-126.326q-14.425 0-28.214-4.62-13.79-4.619-24.932-15.826l-57.819-54.076q-122.478-113-214.239-218.858-91.76-105.859-91.76-226.098 0-96.88 65.439-162.016 65.439-65.137 160.266-65.137 53.533 0 103.294 24.663 49.761 24.664 87.957 74.381 43.196-49.717 89.956-74.381 46.761-24.663 101.05-24.663 95.774 0 161.221 65.137 65.447 65.136 65.447 162.016 0 120.239-92.62 226.217-92.619 105.978-212.337 218.217l-59.563 54.802q-11.013 11.145-24.716 15.693-13.704 4.549-28.43 4.549Zm-31.53-560.565q-22.717-43.957-66.865-76.359-44.148-32.402-92.237-32.402-64.551 0-106.789 41.987-42.239 41.988-42.239 108.526 0 56.725 38.479 120.833t92.538 123.94q54.058 59.832 112.131 111.54 58.073 51.709 96.05 86.196 38.067-34.761 96.091-87.055 58.024-52.293 112.498-111.924 54.474-59.63 93.354-123.408t38.88-120.801q0-65.791-42.44-107.812-42.44-42.022-107.052-42.022-48.883 0-93.761 31.902-44.877 31.902-67.355 76.859-5.071 9.761-12.837 14.141-7.767 4.38-17.45 4.38-9.513 0-17.661-4.38-8.149-4.38-13.335-14.141ZM480-499.761Z"/>
                  </svg>
                  <svg v-else style="margin-top: 3px" xmlns="http://www.w3.org/2000/svg" height="48"
                       viewBox="0 -960 960 960" width="48">
                    <path fill="#0BABAB"
                          d="M479.761-126.326q-14.718 0-28.435-4.739-13.717-4.739-24.435-15.457l-58.087-54.326q-122.478-113-214.239-218.858-91.76-105.859-91.76-226.098 0-96.718 65.238-161.935 65.24-65.218 160.957-65.218 53.043 0 102.804 24.663 49.761 24.664 87.957 74.381 43.196-49.717 89.956-74.381 46.761-24.663 100.805-24.663 95.956 0 161.435 65.218 65.478 65.217 65.478 161.935 0 120.239-92.62 226.217-92.619 105.978-212.337 218.217l-59.608 54.848q-10.718 10.718-24.555 15.457-13.837 4.739-28.554 4.739Z"/>
                  </svg>
                </div>
                <div v-if="false" class="restaurant__card--time">
                  <img src="/img/marketplace/rocket.svg" alt=""/>
                  <span>45 {{$t('min')}}</span>
                </div>
              </div>
              <div class="d-flex align-items-baseline justify-content-between pr-10 pl-10">
                <h2 class="restaurant__card--title">{{ store.name }}</h2>
                <span class="restaurant__card--mileage">{{ (+store.distance).toFixed(1) }} {{$t('km')}}</span>
              </div>
              <div v-if="false" class="restaurant__card--actions">
                <img src="/img/marketplace/fire.svg" alt=""/>
                <span class="rate">{{ store.rating }}</span>
                <p>Рекомендуем</p>
                <div>PPP</div>
              </div>
            </div>
          </template>
        </transition-group>
        <p v-if="!end" id="load-more-check" ref="loadMoreCheck" class="load-more-check w-100 text-center">{{$t('loading')}}</p>
      </div>
      <h2 v-if="checkHasAnyProducts">not found</h2>
      <van-empty
          v-if="!activeStores?.length"
          image="search"
          :description="$t('not-found')"
          style="margin-bottom: 200px"
      />
    </div>
  </section>
</template>
<script>

import {Swiper, SwiperSlide} from "swiper-vue2";
import {Empty} from "vant";
import 'swiper/swiper-bundle.css';
import Filter from "@/models/Filter";

export default {
  name: "RestaurantBlock",
  components: {
    Swiper,
    SwiperSlide,
    "van-empty": Empty,
    // "van-sticky": Sticky
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    restaurantCards: {
      type: Array,
      default: () => []
    },
    search: {
      type: String,
      default: ""
    },
    searching: {
      type: String,
      default: null
    },
    currentFilter: {
      type: String,
      default: "all"
    },
    end: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    return {
      swiperRestaurantOptions: {
        breakpoints: {
          0: {
            slidesPerView: 4
          },
          376: {
            slidesPerView: 5
          },
          481: {
            slidesPerView: 6.4
          },
          576: {
            slidesPerView: 8.4
          },
          768: {
            slidesPerView: 10.4
          }
        }
      },
      filter: false,
      activeIndex: 4,
      currentStore: {},
    };
  },
  methods: {
    changeFilter() {
      this.$store.commit("UPDATE_QUERY_COLLECT");
      this.$store.commit("CHECK_QUERY_COLLECT");
      const query = {
        ...this.$route.query,
        kitchens: this.$store.getters["queryParams"].kitchens
      };
      this.$router.push({query}).catch(() => {
      });
      this.calcParams();
      this.$emit("filter-restaurant");
    },
    calcParams() {
      const match = document.location.search.match(/=/g);
      if (match && match.length) {
        this.$store.commit("QUERY_PARAMS_COUNT", JSON.stringify(this.$route.query).includes("avg_from") ? match.length - 1 : match.length);
      } else {
        this.$store.commit("QUERY_PARAMS_COUNT", 0);
      }
    },
    goToDetail(store) {
      this.$router.push({name: "CatalogPage", params: {brand: store.brand_id, store: store.id}});
    },
    favorite(currentStore) {
      this.$store.dispatch("addToFavorite", currentStore);
    },
    inFavorite(store) {
      return this.$store.getters['favorites'].find(fav => fav.id * 1 === store.id * 1);
    },
    scrolling() {
      this.$nextTick(() => {
        if (this.$refs.rSwiper) {
          const scrollContainer = this.$refs.rSwiper?.$el;
          if (this.$refs?.rSwiperRow) {
            const scrollRow = this.$refs.rSwiperRow;
            const array = [scrollContainer, scrollRow];
            array.forEach(element => {
              element.addEventListener("wheel", (evt) => {
                evt.preventDefault();
                element.scrollLeft += evt.deltaY;
              });
            })
          }
        }
      });
    },
  },
  created() {
    this.calcParams();
  },
  computed: {
    activeStores() {
      return this.restaurantCards ? structuredClone(this.restaurantCards) : [];
    },
    checkHasAnyProducts() {
      return !this.activeStores.filter(item => {
        if (item.category) {
          return item.category.includes(this.currentFilter);
        }
      });
    },
    kitchens: {
      get() {
        return this.$store.getters["queryParams"].kitchens;
      },
      set(value) {
        return this.$store.commit("UPDATE_QUERY_PARAMS_KITCHEN", value);
      }
    },
    stores() {
      return this.filters.filter(f => f.type === "cuisine");
    },
    checkFilterStatus() {
      return JSON.stringify(this.$store.state.queryParams) !== JSON.stringify(new Filter({}));
    },
  },
  beforeCreate() {
    const query = this.$route.query;
    const clone = {...query};
    this.$store.commit("UPDATE_ROUTE_QUERY_PARAMS", {...clone});
    this.$store.commit("CHECK_QUERY_COLLECT");

    if ("kitchens" in query) {
      if (typeof query.kitchens === "string") {
        this.$route.query.kitchens = this.$route.query.kitchens.split();
      }
      if (typeof this.$store.state.queryParams.kitchens === "string") {
        this.$store.state.queryParams.kitchens = this.$store.state.queryParams.kitchens.split();
      }
    }
  },
  watch: {
    filters(value) {
      if (value.length) this.scrolling();
    },
    // search: {
    //   handler(value) {
    //     this.searching = value;
    //   },
    //   deep: true,
    //   immediate: true
    // }
  }
};
</script>

<style lang="scss">
.restaurant-fixed {
  margin-left: -6px;
  margin-top: -15px;
  @media (min-width: 440px) {
    margin-left: -11px;
  }
}

.restaurant-fixed .van-sticky--fixed {
  background-color: #FFFFFF;
  padding: 10px 16px;
  z-index: 2;
  box-shadow: 0 1px 10px #0000000d;
  max-width: 768px;
  margin: 60px auto 0 !important;
}

.restaurant-group-wrapper {
  margin-bottom: 50px;
  margin-top: 25px;
}

.restaurant-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.restaurant-group-enter {
  transform: scale(0.5) translateY(-80px);
  opacity: 0;
}

.restaurant-group-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

.restaurant-group-leave-active {
  position: absolute;
  z-index: -1;
}

.restaurant__card {
  transition: all .25s ease-in-out;
}

</style>
