<template>
  <div></div>
</template>

<script>
export default {
  title: "Оплата счета и чаевых",
  name: "CheckViewRedirect",
  created() {},
  beforeCreate() {
    // this.$router.push({
    //   name: "CheckView",
    //   params: this.$route.params,
    //   query: this.$route.query,
    // });
    this.$router.push({
      name: "PreCheck",
      params: {
        store: this.$route.params.storeId
      },
      query: this.$route.query,
    });
  },
};
</script>
