<template>
  <div class="pre-check-content">
    <LoadingBlock v-if="stateLoading" />
  </div>
</template>
<script>
import LoadingBlock from "@/components/Loading.vue";

export default {
  title: "Оплата счета и чаевых",
  name: "PreCheck",
  components: {LoadingBlock},
  beforeCreate() {
    if (window.location.href.startsWith('https://app.rhmt.uz')) {
      window.location.href = window.location.href.replace('app.rhmt.uz', 'app2.rhmt.uz')
    } else if (window.location.href.startsWith('https://tips.multicard.uz')) {
      window.location.href = window.location.href.replace('tips.multicard.uz', 'app2.rhmt.uz')
    } else {
      window.location.href = window.location.href.replace('dev-tips.multicard.uz', 'dev-app.rhmt.uz')
    }
    // window.location = 'https://app2.rhmt.uz' + window.location.pathname + window.location.search
  },
  computed: {
    stateLoading() {
      return this.$store.state.loading;
    },
  },
};
</script>
